import { AppProps } from "next/app";
import { Settings } from "luxon";
import "@fontsource/jetbrains-mono";
import "@fontsource/outfit";
import "@fontsource/inter";
import "@fontsource/inter/variable.css";
import "tailwindcss/tailwind.css";
import "lib/styles.css";

// TODO: should set to development environment?
Settings.throwOnInvalid = true;

// TODO: Need to implement Nextjs persistent layouts
function App({ Component, pageProps }: AppProps) {
  return <Component {...pageProps} />;
}

export default App;
